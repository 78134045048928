import { all, takeLatest } from 'redux-saga/effects';

import {
  authLoginAction,
  authLogoutAction,
  authSignUpAction,
  sendMailAction,
  resetPasswordAction,
  changePasswordAction,
  updateProfileAction,
  updateUserAction,
  getUserInfoAction,
  verifyOtpAction,
  resendOtpAction,
  requestVerifyUserEmailAction,
} from '@/redux/actions';

import { authLoginSaga } from './auth-login';
import { authSignUpSaga } from './signup';
import { authLogoutSaga } from './auth-logout';
import { sendMailSaga } from './send-mail';
import { resetPasswordSaga } from './reset-password';
import { changePasswordSaga } from './change-password';
import { updateProfileSaga } from './update-profile';
import { updateUserSaga } from './update-user';
import { getUserInfoSaga } from './get-user-info';
import { verifyOtpSaga } from './verify-otp';
import { resendOtpSaga } from './resend-otp';
import { requestVerifyUserEmailSaga } from './request-verify-user-email';

export default function* root(): Generator {
  yield all([
    takeLatest(authLoginAction.request.type, authLoginSaga),
    takeLatest(authSignUpAction.request.type, authSignUpSaga),
    takeLatest(authLogoutAction.request.type, authLogoutSaga),
    takeLatest(sendMailAction.request.type, sendMailSaga),
    takeLatest(resetPasswordAction.request.type, resetPasswordSaga),
    takeLatest(changePasswordAction.request.type, changePasswordSaga),
    takeLatest(updateProfileAction.request.type, updateProfileSaga),
    takeLatest(updateUserAction.request.type, updateUserSaga),
    takeLatest(getUserInfoAction.request.type, getUserInfoSaga),
    takeLatest(verifyOtpAction.request.type, verifyOtpSaga),
    takeLatest(resendOtpAction.request.type, resendOtpSaga),
    takeLatest(requestVerifyUserEmailAction.request.type, requestVerifyUserEmailSaga),
  ]);
}
